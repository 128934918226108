import { useEffect, useState } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { reOrderRows, removeDuplicates } from '../utils/utilFunctions';
import { useGetApplicationProcessQuery } from '../services/endpoints/programsEndpoints';
import { getEntityPrefix } from '../utils/utilFunctions';
import { getLocalAuth } from '../utils/environmentUtils';
import { setApplicationProcessData } from '../components/FormBuilderLibrary/slice';

export const useApplicationSetup = () => {
	const [searchParams] = useSearchParams();
	const dispatch = useDispatch();

	let { programID } = useParams();
	programID = programID?.replace('p-', '');
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const apiToken = getLocalAuth(accountSource)?.access_token;
	const programTitle = useSelector((state) => state?.globalProgramVariables?.program?.title);
	const [rowData, setRowData] = useState([]);
	const [loading, setLoading] = useState(true);

	const [skip, setSkip] = useState(true);
	const { data, refetch, error, isUninitialized, isFetching } = useGetApplicationProcessQuery(
		{
			programID,
			apiToken
		},
		{ skip }
	);

	useEffect(() => {
		setLoading(isFetching);
	}, [isFetching]);

	useEffect(() => {
		setLoading(true);
		const applicationProcess = error?.status === 404 ? {} : data;
		const ApplicationPrefix = getEntityPrefix({ type: 'application-process', isMaters: true });
		let copiedRowData = [];
		let title =
			programTitle ||
			applicationProcess?.process_name?.replace('Application Process for Program: ', '');

		if (applicationProcess?.applied_process?.length > 0) {
			title = `Application Process for Program: ${title}`;
		}

		const initialRow = {
			hierarchy: [applicationProcess?.programId || programID],
			type: 'APPLICATION PROCESS',
			title: title,
			appId: applicationProcess?.process_id,
			businessLogic: applicationProcess?.stage_order?.length > 0,
			instructions: applicationProcess?.instructions,
			startDate: applicationProcess?.start_date,
			endDate: applicationProcess?.end_date,
			isReadyToPublish: applicationProcess?.is_ready_to_publish,
			publishableAt: applicationProcess?.publishable_at,
			in_use: applicationProcess?.in_use,
			status: applicationProcess?.program.status,
			id: 0,
			programID: programID,
			lastEdited: {
				...applicationProcess?.updated_by_user,
				time: applicationProcess?.last_updated_on
			},
			uniqueIdentifier:
				applicationProcess?.unique_identifier &&
				`${ApplicationPrefix}${applicationProcess?.unique_identifier}`
		};
		const applicationProcessPublicationDate = applicationProcess?.publishable_at
			? new Date(applicationProcess?.publishable_at)
			: null;
		if (applicationProcess?.stages) {
			let prevSubmissionStage = null;
			let submissionStageCount = 0;
			applicationProcess?.stages?.forEach((e, index) => {
				const StagePrefix = getEntityPrefix({
					type: e?.stage_type === 'application' ? 'submission-stage' : 'evaluation-stage',
					isMaters: true
				});
				const stageType = e?.stage_type?.includes('application')
					? 'Submission Stage'
					: 'Evaluation Stage';

				if (stageType?.includes('Submission')) {
					prevSubmissionStage = e;
					submissionStageCount += 1;
				}

				const hasCoreInfo = e?.forms?.find(
					(innerForm) => innerForm?.purpose === 'Core Information'
				);

				let hasSubsequentSubmissionStage = false;
				if (stageType?.includes('Evaluation')) {
					// SAVE SUBSEQUENT STAGE
					for (let i = index + 1; i < applicationProcess?.stages?.length; i++) {
						const nextStage = applicationProcess?.stages[i];
						if (nextStage?.stage_type?.includes('application')) {
							hasSubsequentSubmissionStage = true;
						}
					}
				}

				const createdDate = new Date(e?.created_on);
				const createdBeforePublication = applicationProcess?.is_ready_to_publish
					? createdDate < applicationProcessPublicationDate
					: false;
				copiedRowData.push({
					hierarchy: [programID, e?.stage_id],
					type: stageType,
					title: e?.stage_name,
					appId: e?.stage_id,
					businessLogic: e?.form_order?.length > 0,
					instructions: e?.instructions,
					in_use: e?.in_use,
					startDate: e?.start_date,
					endDate: e?.end_date,
					id: copiedRowData?.length + 1,
					review_info: e?.review_info,
					lastEdited: {
						...e?.updated_by_user,
						time: e?.last_updated_on
					},
					uniqueIdentifier: `${StagePrefix}${e?.unique_identifier}`,
					hasCoreInfo: hasCoreInfo ? true : false,
					coreFormID: hasCoreInfo?.form_id,
					hasForms: e?.forms?.length > 0,
					isReadyToPublish: applicationProcess?.is_ready_to_publish,
					hasSubsequentSubmissionStage: hasSubsequentSubmissionStage,
					createdBeforePublication,
					submissionStageCount: submissionStageCount
				});

				e?.form_order?.forEach((i) => {
					const currentForm = e?.forms?.find((x) => x?.form_id === i);
					const FormPrefix = getEntityPrefix({
						type: currentForm?.form_type === 'application' ? 'application-form' : 'review-form',
						isMaters: true
					});
					const createdDate = new Date(currentForm?.created_on);
					const createdBeforePublication = applicationProcess?.is_ready_to_publish
						? createdDate < applicationProcessPublicationDate
						: false;
					currentForm &&
						copiedRowData.push({
							hierarchy: [programID, e?.stage_id, currentForm?.form_id],
							type: stageType === 'Submission Stage' ? 'Application Form' : 'Review Form',
							title: currentForm?.label,
							appId: currentForm?.form_id,
							businessLogic: null,
							instructions: currentForm?.instructions,
							in_use: currentForm?.in_use,
							startDate: currentForm?.start_date,
							endDate: currentForm?.end_date,
							order: currentForm?.order,
							id: copiedRowData?.length + 1,
							stage_id: e?.stage_id,
							isReadyToPublish: applicationProcess?.is_ready_to_publish,
							createdBeforePublication,
							lastEdited: {
								...currentForm?.updated_by_user,
								time: currentForm?.last_updated_on
							},
							uniqueIdentifier: `${FormPrefix}${currentForm?.unique_identifier}`,
							purpose: currentForm?.purpose
						});
				});

				e?.forms?.forEach((x) => {
					const createdDate = new Date(x?.created_on);
					const createdBeforePublication = applicationProcess?.is_ready_to_publish
						? createdDate < applicationProcessPublicationDate
						: false;

					const FormPrefix = getEntityPrefix({
						type: x?.form_type === 'application' ? 'application-form' : 'review-form',
						isMaters: true
					});
					const currentForm = e?.copiedRowData?.find((i) => i?.form_id === x?.form_id);
					!currentForm &&
						copiedRowData.push({
							hierarchy: [programID, e?.stage_id, x?.form_id],
							type: stageType === 'Submission Stage' ? 'Application Form' : 'Review Form',
							title: x?.label,
							appId: x?.form_id,
							businessLogic: null,
							instructions: x?.instructions,
							in_use: x?.in_use,
							startDate: x?.start_date,
							endDate: x?.end_date,
							order: x?.order,
							id: copiedRowData?.length + 1,
							isReadyToPublish: applicationProcess?.is_ready_to_publish,
							createdBeforePublication,
							stage_id: e?.stage_id,
							lastEdited: {
								...x?.updated_by_user,
								time: x?.last_updated_on
							},
							uniqueIdentifier: `${FormPrefix}${x?.unique_identifier}`,
							purpose: x?.purpose
						});
				});
			});
		}
		const noDuplicatedRows = removeDuplicates(copiedRowData);
		if (applicationProcess?.stage_order?.length > 0) {
			const temp = reOrderRows(applicationProcess?.stage_order, noDuplicatedRows);
			setRowData([initialRow, ...temp]);
		} else {
			setRowData([initialRow, ...noDuplicatedRows]);
		}
		setLoading(false);
	}, [data, error]);

	useEffect(() => {
		setSkip(!programID);
	}, [searchParams]);

	useEffect(() => {
		dispatch(setApplicationProcessData(rowData[0]));
	}, [rowData, dispatch]);

	const refetchRowData = () => {
		if (!isUninitialized) refetch({ force: true, skip: false });
	};

	return {
		data: rowData,
		isLoading: loading,
		refetch: refetchRowData
	};
};
