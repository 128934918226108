import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import { AlertProps } from '@mui/material/Alert/Alert';
import _castArray from 'lodash/castArray';
import Typography from '@mui/material/Typography';
import type { Variant } from '@mui/material/styles/createTypography';

interface AlertBalloonProps extends AlertProps {
	open: boolean;
	message: string | string[];
	typographyVariant?: Variant | 'inherit';
	onClose?: () => void;
}

export const AlertBalloon = ({
	onClose,
	open,
	severity,
	message,
	typographyVariant = 'body2',
	...alertProps
}: AlertBalloonProps) => {
	const messages = _castArray(message);
	const actionComponent = onClose ? (
		<IconButton aria-label="close" color={severity} size="small" onClick={onClose}>
			<CloseIcon fontSize="inherit" />
		</IconButton>
	) : (
		<></>
	);

	return (
		<Collapse in={open}>
			<Alert {...alertProps} severity={severity} action={actionComponent} sx={{ mb: 1 }}>
				{messages.map((message) => (
					<Typography key={`alert-balloon-${message}`} variant={typographyVariant}>
						{message}
					</Typography>
				))}
			</Alert>
		</Collapse>
	);
};
